<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card>
    <v-card-title>{{ $t('menu.translate') }} / {{ $t('menu.products') }} / {{ $t('menu.hotels') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            :disabled="!itemEdit.id"
            @click="save"
          >
            <span>{{ $t('btn.update') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <span
            v-if="character_limit"
            style="font-size: 10px;"
          >
            <i>
              {{ `${character_count} / ${character_limit}` }}
            </i>
          </span>
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="success"
            class="mb-4 me-3"
            :loading="loadingTranslate"
            :disabled="disabledTranslate || !item.id"
            @click="translate"
          >
            <span>{{ $t('btn.translate') }}</span>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-form class="multi-col-validation">
        <v-row>
          <v-col cols="12">
            <span
              v-if="cant_sin_trans > 0"
              style="color: red"
            >
              {{ cant_sin_trans }} {{ $t('dashboard.inProcess') | lowercase }}
            </span>
          </v-col>
          <v-col
            cols="12"
            md="5"
          >
            <v-autocomplete
              v-model="item.id"
              :items="itemsHotels"
              :search-input.sync="searchHotel"
              hide-details
              hide-selected
              :label="$t('lbl.hotel')"
              outlined
              dense
              item-text="name"
              item-value="id"
              clearable
              @change="setHotel"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.hotel') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <span v-text="item.name"></span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }} <span
                      v-if="!item.descripcion_pt"
                      style="color: red"
                    >{{ `(${$t('lbl.pendiente')})` | lowercase }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-if="itemEdit.descripcion"
            cols="12"
          >
            <v-tabs
              v-model="pos"
              icons-and-text
            >
              <v-tab>ES</v-tab>
              <v-tab>PT</v-tab>
              <v-tab>EN</v-tab>
              <v-tab>DE</v-tab>
              <v-tab>RU</v-tab>
              <v-tab>FR</v-tab>
            </v-tabs>
            <v-card>
              <v-card-text>
                <v-tabs-items v-model="pos">
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion"
                      :extensions="extensions"
                      :placeholder="`${$t('lbl.description')}...`"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion_pt"
                      :extensions="extensions"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion_en"
                      :extensions="extensions"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion_de"
                      :extensions="extensions"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion_ru"
                      :extensions="extensions"
                    />
                  </v-tab-item>
                  <v-tab-item>
                    <tiptap-vuetify
                      v-model="itemEdit.descripcion_fr"
                      :extensions="extensions"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TiptapVuetify,
  },
  data() {
    return {
      pos: 0,
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      items: [],
      itemsHotels: [],
      searchHotel: null,
      item: {},
      itemEdit: {
        descripcion: null,
      },
      showMap: true,
      loading: false,
      isLoadingHotels: true,
      loadingTranslate: false,
      permisos: localStorage.getItem('permisos_auth'),
      disabledTranslate: true,
      character_count: null,
      character_limit: null,
      cant_translate: 0,
      cant_sin_trans: 0,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      hotelsProductCache: state => state.app.hotelsProductCache,
    }),
  },
  watch: {
    searchHotel(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterHotel(val.toLowerCase())
      } else {
        this.itemsHotels = []
      }
    },
  },
  created() {
    if (this.hotelsProductCache.length > 0) {
      this.items = this.hotelsProductCache
      this.isLoadingHotels = false
    }
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    chengeCantTranslate() {
      if (this.character_limit > 0) {
        this.disabledTranslate = false
      }
    },
    filterHotel(v) {
      this.itemsHotels = []
      if (v === '') {
        this.itemsHotels = []
      } else {
        this.itemsHotels = this.items.filter(e => e.name.toLowerCase())
      }
    },
    async fetchDataFromEndpoints() {
      const jsonItems = {
        page: 1,
        per_page: 100000000,
      }

      try {
        const [
          resItems,
          resCount,
        ] = await Promise.all([
          this.axios
            .post('hotels/list', jsonItems, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('translate-hotels/pending-translate', {}, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.items = resItems.data.data
        }

        if (resCount.data.success === true) {
          this.cant_sin_trans = resCount.data.cant
        }
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.isLoadingHotels = false
        this.getLimit()
      }
    },
    async getLimit() {
      this.disabledTranslate = true
      this.character_count = null
      this.character_limit = null
      await this.axios
        .post('traslate/get-limit', { }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.character_count = res.data.data.character_count
            this.character_limit = res.data.data.character_limit
            if (res.data.data.character_count < res.data.data.character_limit) {
              this.disabledTranslate = false
            }
          }
        })
        .finally(() => this.chengeCantTranslate())
    },
    setHotel() {
      if (this.item.id) {
        const model = this.items.filter(e => e.id === this.item.id)
        if (model.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          this.itemEdit = model[0]
        }
      } else {
        this.itemEdit = {
          descripcion: null,
        }
      }
    },

    async save() {
      this.loading = true
      await this.axios
        .post('translate-hotels/update', this.itemEdit, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) { } else {
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.reseat())
    },
    async translate() {
      if (this.itemEdit.descripcion) {
        this.loadingTranslate = true
        await this.axios
          .post('translate-hotels/translate', this.itemEdit, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
              this.$toast.error(response.data.message)
            } else {
              this.getLimit()
              this.itemEdit = response.data.data.item
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .finally(() => {
            this.loadingTranslate = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reseat() {
      this.loading = false
      this.loadingTranslate = false
    },
  },
}
</script>
