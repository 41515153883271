<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <HotelProduct />
    </v-col>
  </v-row>
</template>

<script>
import HotelProduct from './form/HotelProduct.vue'

export default {
  components: {
    HotelProduct,
  },
}
</script>
